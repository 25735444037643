import React from 'react';
import { graphql } from 'gatsby';

import { CampusPage } from '../../interface/template';
import NewVariant from './variants/new';

const Campus: React.SFC<any> = (props: CampusPage) => <NewVariant {...props} />;

export const query = graphql`
  query ($locale: String!, $campusCodeDato: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(staff)|(campus)|(course-list)|(seo)|(breadcrumbs)|(banner)|(covid)|(course-web-dev-rmt)|(blog)|(campus-contact-info)|(upcoming-events)|(course-campus)|(request-syllabus)|(application-form)/"
        }
      }
    ) {
      ...TranslationFragment
    }
    allDatoCmsCampusinformation(
      filter: {
        campus: { code: { eq: $campusCodeDato } }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        locale
        street
        zipCode
        campus {
          code
          description
          order
          isOnline
          isNew
        }
        country
        phoneNumber
        email
        sliderImages {
          fluid(maxWidth: 1440, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        googleMapsUrl
        header {
          title
          subtitle
          description
          cta
          imageDesktop {
            gatsbyImageData(width: 1280, layout: FULL_WIDTH)
            title
            alt
          }
          imageMobile {
            gatsbyImageData(width: 428, layout: FULL_WIDTH)
            title
            alt
          }
        }
        campusValuePropsTitle
        campusvalueprops {
          title
          image {
            gatsbyImageData(width: 350, layout: FULL_WIDTH)
            title
            alt
          }
          reasons {
            emoji
            text {
              value
            }
          }
        }
      }
    }
  }
`;

export default Campus;
