import React, { useCallback } from 'react';
import {
  Box,
  Flex,
  forwardRef,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StructuredText } from 'react-datocms';
import { GatsbyImage } from 'gatsby-plugin-image';

import { dynamicAlignment } from '../../lib/utils';
import { DatoCampusValueProp } from '../../services/interfaces';

type HybridValuePropositionProps = {
  campusInfo: {
    campus: {
      code: string;
      description: string;
    };
    campusvalueprops: DatoCampusValueProp[];
  };
};

const NewCampusValueProposition = (props, ref): React.ReactElement => {
  const {
    campus: { code },
    campusvalueprops,
    campusValuePropsTitle,
  } = props.campusInfo;
  const { t } = useTranslation();
  const campusCode = code.toLowerCase();

  const alignCard = useCallback(dynamicAlignment, []);

  return (
    <Box
      background="linear-gradient(240.24deg, rgba(253, 167, 54, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(120.01deg, rgba(45, 197, 250, 0.12) 5.51%, rgba(86, 76, 255, 0.08) 100%);"
      px={[3, null, 4, 0]}
      py={[4, null, null, 6]}
      ref={ref}
      transform="scaleX(-1)"
    >
      <Box
        margin={[null, null, null, 'auto']}
        maxW={[null, null, null, '1296px']}
        transform="scaleX(-1)"
      >
        <Text textStyle="eyebrow" color="orange.100">
          {t('menu:menu.campuses.virtualLabel')}
        </Text>
        <Text textStyle="title1">{campusValuePropsTitle}</Text>
        <Grid
          gap={[5, null, null, 12]}
          mt={[4, null, null, 5]}
          templateColumns={['1fr', null, 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
        >
          {campusvalueprops.map(({ title, reasons, image }) => (
            <GridItem>
              <Box maxWidth={[null, null, '300px']}>
                <Image
                  alt={image.alt}
                  as={GatsbyImage}
                  borderRadius="card"
                  boxSize={['100%', null, '300px']}
                  fit="cover"
                  image={image.gatsbyImageData}
                  key={title}
                  title={image.title}
                />
                <Box mt={[2, null, null, 3]}>
                  <Text textStyle="body2" align="center">
                    {title}
                  </Text>
                </Box>
                <Stack mt={3} spacing={1}>
                  {reasons.map(({ emoji, text }, index) => (
                    <Flex
                      align="center"
                      alignSelf={alignCard(index)}
                      bgColor="whiteAlpha.80"
                      borderRadius="card"
                      boxShadow="4px 4px 8px rgba(0, 0, 0, 0.06);"
                      key={text}
                      p={1.5}
                    >
                      <Text as="span" mr={0.5} textStyle="body4">
                        {emoji}
                      </Text>
                      <Text display="inline-block" textStyle="body5">
                        <StructuredText data={text.value} />
                      </Text>
                    </Flex>
                  ))}
                </Stack>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default forwardRef<HybridValuePropositionProps, 'div'>(
  NewCampusValueProposition
);
