import styled, { css } from 'styled-components';
import config from '../config';

const TextInformation = styled.article`
  text-aling: end;
`;

const CampusInformation = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  article {
    margin-bottom: 3.2rem;
    padding-right: 9.6rem;
    width: calc(50% - 3.2rem);

    & > p {
      color: ${config.palette.greyTransparent('0.8')};
      font-size: 2rem;
      line-height: 160%;
      margin-top: 1.6rem;
    }

    & > h3 {
      color: ${config.palette.grey};
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    article {
      padding-right: 0;
      width: 100%;

      & > p {
        font-size: 1.6rem;
        line-height: 150%;
        margin-top: 1.2rem;
      }
    }
  }
`;

const BottomImage = styled.section`
  display: flex;
  padding-top: 2.4rem;
  justify-content: space-between;

  & > article:last-child {
    width: 100%;
    text-align: end;

    b {
      color: ${config.palette.white};
    }

    p {
      color: ${config.palette.whiteTransparent('0.4')};
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-wrap: wrap;

    & > article:last-child {
      margin-bottom: 4.8rem;
    }
  }
`;

const Pagination = styled.section`
  width: 100%;
  display: flex;
  padding: 2rem 0;
`;

const Swipeable = styled.div`
  display: flex;
  align-items: center;

  svg:first-child {
    margin-right: 2.4rem;
  }

  svg {
    cursor: pointer;
    user-select: none;

    path {
      fill: white;
    }
  }
`;

const PaginationItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 6rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-right: 2.8rem;
  }
`;

const BlogIndex = styled('article')`
  width: 100%;
  height: 0.2rem;
  margin-right: 0.4rem;
  background-color: ${config.palette.whiteTransparent('0.4')};

  &.active {
    background-color: ${config.palette.white};
  }
`;

const LayoutCampusValue = css`
  background-color: ${config.palette.darkBlue};
  padding-top: 4rem;
  padding-bottom: 4rem;

  h2 {
    color: ${config.palette.white};
    margin-bottom: 1.6rem;
  }

  &.remoteCampus {
    padding-top: 4.8rem;

    .subtitle {
      color: ${config.palette.shadowGreyTransparent('0.8')};
      font-size: 2rem;
      line-height: 150%;
      margin-bottom: 4.8rem;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    &.remoteCampus {
      padding-top: 3.2rem;

      .subtitle {
        font-size: 1.6rem;
        line-height: 140%;
        margin-bottom: 3.2rem;
      }
    }
  }
`;

const ImageLayout = styled.section`
  height: 60rem;
  margin-bottom: -0.2rem;
  width: 100%;

  @media (max-width: ${config.resolutions.mobileL}) {
    height: auto;
  }
`;

const CarouselCampusValue = styled.img`
  height: 60rem;
  margin-bottom: -0.2rem;
  object-fit: cover;
  width: 100%;

  @media (max-width: ${config.resolutions.mobileL}) {
    height: auto;
  }
`;

export {
  TextInformation,
  CampusInformation,
  Pagination,
  Swipeable,
  PaginationItems,
  BlogIndex,
  BottomImage,
  LayoutCampusValue,
  CarouselCampusValue,
  ImageLayout,
};
