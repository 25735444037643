import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Hexagon = createIcon({
  displayName: 'Hexagon',
  viewBox: '0 0 128 128',
  path: (
    <path
      d="M59 1.3094010767585a8 8 0 0 1 8 0l47.425625842204 27.381197846483a8 8 0 0 1 4 6.9282032302755l0 54.762395692966a8 8 0 0 1 -4 6.9282032302755l-47.425625842204 27.381197846483a8 8 0 0 1 -8 0l-47.425625842204 -27.381197846483a8 8 0 0 1 -4 -6.9282032302755l0 -54.762395692966a8 8 0 0 1 4 -6.9282032302755"
      fill="currentColor"
    />
  ),
});

export default Hexagon;
