import { updateDataLayer } from '../../lib/datalayer';

function emitSlideEvent(direction: string) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click icon',
    eventLabel: `why campus::navigation arrow::${direction}`
  });
}

export { emitSlideEvent };
