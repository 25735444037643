import React from 'react';
import { Box, Button, Flex, forwardRef, Image, Text } from '@chakra-ui/react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Markdown from 'react-markdown';

import Navbar from '../../components/NavBar';
import Hexagon from '../../components/Shapes/Hexagon';

type Props = {
  title: string;
  subtitle: string;
  description: string;
  cta: string;
  imageDesktop: IGatsbyImageData;
  imageMobile: IGatsbyImageData;
  pageName: string;
  onCtaClick: () => void;
};

const HeaderBgImage = (
  {
    cta,
    description,
    imageDesktop,
    imageMobile,
    pageName,
    onCtaClick,
    subtitle,
    title,
  },
  ref
): React.ReactElement => (
  <Flex direction="column" overflow="hidden" position="relative" ref={ref}>
    <Hexagon
      boxSize={['60em', null, '84em']}
      color="white"
      left={['-12em', null, '-10em']}
      opacity="90%"
      pos="absolute"
      top={['19em', null, '3em', '-22em']}
      transform={['rotate(14deg)', null, 'rotate(22deg)', 'rotate(14deg)']}
      zIndex="base"
    />
    <Image
      alt={imageMobile?.alt}
      as={GatsbyImage}
      display={['block', null, 'none']}
      fit="cover"
      height={['58rem', null, '50rem']}
      image={imageMobile?.gatsbyImageData}
      position="absolute"
      title={imageMobile?.title}
      width="100%"
      zIndex="hide"
    />
    <Image
      alt={imageDesktop?.alt}
      as={GatsbyImage}
      display={['none', null, 'block']}
      fit="cover"
      height={['58rem', null, '50rem']}
      image={imageDesktop?.gatsbyImageData}
      position="absolute"
      title={imageDesktop?.title}
      width="100%"
      zIndex="hide"
    />
    <Box as={Navbar} isDarkBackground pageName={pageName} zIndex="dropdown" />
    <Flex
      direction="column"
      maxWidth="46rem"
      mx={[3, null, 9]}
      pb={[2, null, 12]}
      pt={['20rem', null, 6]}
      zIndex="docked"
    >
      <Text order={[2, null, 1]} textStyle="title1">
        {title}
      </Text>
      <Text
        color="coral.100"
        fontSize={2}
        mt={1}
        order={[1, null, 2]}
        textStyle="eyebrow"
      >
        {subtitle}
      </Text>
      <Text as={Markdown} mt={4} order={3} textStyle="body4">
        {description}
      </Text>
      <Button
        as="a"
        colorScheme="primary"
        h="4rem"
        mt={4}
        onClick={onCtaClick}
        order={4}
        size="lg"
        w={['100%', null, 'auto']}
      >
        {cta}
      </Button>
    </Flex>
  </Flex>
);

export default forwardRef<Props, 'div'>(HeaderBgImage);
