import { updateDataLayer } from '../../lib/datalayer';

function emitViewMeetupEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'campus events::view information'
  });
}

export { emitViewMeetupEvent };
