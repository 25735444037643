import { graphql, useStaticQuery } from 'gatsby';
import campusList from '../campus.json';
import { getCampusMeetup } from '../lib/campus';

interface Meetup {
  name: string;
  venues: {
    address_1: string;
  };
  duration: number;
  local_time: string;
  local_date: string;
  group: {
    urlname: string;
    name: string;
  };
  time: number;
  link: string;
}

interface MeetupEvents {
  nodes: Meetup[];
}

export default class MeetupsByCampus {
  private campusCode: string;
  private meetupsAllCampus: MeetupEvents;
  public constructor(campusCode: string) {
    this.campusCode = (
      campusList.find(
        (campus: { code: string }) => campus.code === campusCode
      ) || {}
    ).code;
    this.meetupsAllCampus = this.meetupsAllCampusFunction();
  }

  public meetupsByCampusName(): Array<Meetup> {
    return this.meetupsAllCampus.nodes.filter(this.filterMeetings);
  }

  private meetupsAllCampusFunction(): MeetupEvents {
    const { allMeetupEvent } = useStaticQuery(graphql`
      {
        allMeetupEvent {
          nodes {
            name
            venue {
              address_1
            }
            duration
            local_time
            local_date
            group {
              urlname
              name
            }
            time
            link
          }
        }
      }
    `);
    return allMeetupEvent;
  }

  private filterByCampus = (url: string): boolean => {
    const fullUrl = getCampusMeetup(this.campusCode);
    return fullUrl.endsWith(url + '/');
  };

  private filterByTime = (time: number, days = 90): boolean => {
    const currentDate = new Date();
    const maxTime = currentDate.setDate(currentDate.getDate() + days);
    return time <= maxTime;
  };

  private filterMeetings = (meetup: Meetup): boolean =>
    this.filterByCampus(meetup.group.urlname) && this.filterByTime(meetup.time);
}
