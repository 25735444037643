import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';
import classNames from 'classnames';
import Img from 'gatsby-image';
import { Box } from '@chakra-ui/react';

import DatoContactInfo from '../../services/datoCampusContactInformation';
import Layout from '../atoms/layout';
import {
  BlogIndex,
  BottomImage,
  CampusInformation,
  ImageLayout,
  LayoutCampusValue,
  Pagination,
  PaginationItems,
  Swipeable,
} from './styles';
import { emitSlideEvent } from './ga_events';
import { Title1 } from '../atoms/headers';
import { Text2 } from '../atoms/body';
import ArrowLeft from '../../../static/assets/icons/arrows/arrow-left.svg';
import ArrowRight from '../../../static/assets/icons/arrows/arrow-right.svg';
import Props from '../interfaces/CampusValueProposition';

const CampusValueProposition: React.SFC<any> = (props, ref): JSX.Element => {
  const { campusCode, sliderImages, locale } = props;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const campusContactInfo = new DatoContactInfo(
    locale
  ).contactInformationByCampus(campusCode);
  const campusInfo = campusContactInfo.find(
    (info) => info.campus.code.toLowerCase() === campusCode
  );
  const campusName = (campusInfo && campusInfo.campus.description) || '';

  const cards = sliderImages;
  const isRemoteCampus = campusCode === 'rmt';
  const layoutClass = classNames({
    remoteCampus: isRemoteCampus,
  });

  const changeCard = (num: number): void => {
    const next = currentPage + num;
    if (next < 0) {
      setCurrentPage(cards.length - 1);
    } else if (next >= cards.length) {
      setCurrentPage(0);
    } else {
      setCurrentPage(next);
    }
  };

  const paginationCreator = (): JSX.Element[] => {
    const items = [];
    for (let i = 0; i < cards.length; i++) {
      items.push(
        <BlogIndex
          className={classNames({
            active: i === currentPage,
          })}
          key={uniqid()}
        />
      );
    }
    return items;
  };

  return (
    <Box ref={ref}>
      {!isRemoteCampus && cards?.[currentPage] && (
        <>
          <ImageLayout>
            <Img
              alt={`Campus Ironhack ${campusName}`}
              fluid={cards[currentPage]}
              style={{ height: '60rem' }}
            />
          </ImageLayout>
        </>
      )}
      <Layout className={layoutClass} extend={LayoutCampusValue}>
        {!isRemoteCampus && cards && (
          <BottomImage>
            <Pagination>
              <PaginationItems>{paginationCreator()}</PaginationItems>
              <Swipeable>
                <ArrowLeft
                  onClick={(): void => {
                    emitSlideEvent('left');
                    changeCard(-1);
                  }}
                />
                <ArrowRight
                  onClick={(): void => {
                    emitSlideEvent('right');
                    changeCard(1);
                  }}
                />
              </Swipeable>
            </Pagination>
          </BottomImage>
        )}

        <Title1 as="h2">
          {t(`campus:campus.${campusCode}.valueProp.title`)}
        </Title1>
        {isRemoteCampus ? (
          <p className="subtitle">
            {t(`campus:campus.${campusCode}.valueProp.subtitle`)}
          </p>
        ) : (
          ''
        )}
        <p>{}</p>
        <CampusInformation>
          {Object.values(
            t(`campus:campus.${campusCode}.valueProp.content`, {
              returnObjects: true,
            })
          ).map((content: any) => (
            <article key={uniqid()}>
              <Text2 as="h3">{content.title}</Text2>
              <p>{content.description}</p>
            </article>
          ))}
        </CampusInformation>
      </Layout>
    </Box>
  );
};

export default forwardRef<HTMLDivElement, Props>(CampusValueProposition);
