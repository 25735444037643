import React, { forwardRef } from 'react';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import { Text1, Text3, Text4, Text5 } from '../atoms/body';
import {
  AdressEvent,
  ButtonLink,
  DateEvent,
  Event,
  EventOfTheMonth,
  NameEvent,
} from './styles';
import { emitViewMeetupEvent } from './ga_events';
import campus from '../../campus.json';

type Props = {
  campusCode: string;
  locale: string;
  meetups: any;
  title: string;
};

const UpcomingEvents = (props, ref) => {
  const { campusCode, locale, meetups, title } = props;
  const { t } = useTranslation();

  const campusName = (
    campus.find(
      (campusInfo: { code: string }) => campusInfo.code === campusCode
    ) || { name: '' }
  ).name;

  const languagesCode = {
    br: 'pt-BR',
    de: 'de-DE',
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    nl: 'nl-NL',
    pt: 'pt-PT',
  };

  const language = languagesCode[locale];

  const dateTimeFormat = (
    time: number,
    format: {
      month?: string;
      day?: string;
      weekday?: string;
      hour?: string;
      minute?: string;
      h12?: boolean;
    }
  ) => new Intl.DateTimeFormat(language, format).format(time);

  const concatDateTimeFormat = (event: any) => {
    event.weekday = dateTimeFormat(event.time, {
      weekday: 'short',
    });
    event.day = dateTimeFormat(event.time, {
      day: '2-digit',
    });
    event.start = dateTimeFormat(event.time, {
      hour: '2-digit',
      minute: '2-digit',
    });
    event.end = dateTimeFormat(event.time + event.duration, {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const createMonthEvents = (
    accumulator: Record<string, any[]>,
    event: any
  ) => {
    const month = dateTimeFormat(event.time, { month: 'long' });
    concatDateTimeFormat(event);
    if (month in accumulator) {
      accumulator[month].push(event);
    } else {
      accumulator[month] = [event];
    }
    return accumulator;
  };

  const campusEvents = meetups.reduce(createMonthEvents, {});

  const nameEventComponent = (event: any) => (
    <NameEvent>
      <Text1>{event.name}</Text1>
      <div>
        <img alt={event.name} src="/assets/icons/campus/location.svg" />
        <AdressEvent>
          <Text5 as="p">{event.group.name}</Text5>
          <Text5 as="p">{event.venue ? event.venue.address_1 : ''}</Text5>
        </AdressEvent>
      </div>
    </NameEvent>
  );

  const dateEventComponent = (event: any, month: string) => (
    <DateEvent>
      <Text4 as="p">{`${event.weekday}, ${event.day} ${month}`}</Text4>
      <Text4 as="p">{`${event.start} - ${event.end}`}</Text4>
    </DateEvent>
  );

  const eventsComponent = () =>
    Object.entries(campusEvents).map(([month, events]: any) => (
      <EventOfTheMonth key={uniqid()}>
        <Text3>{month}</Text3>
        <hr />
        {events.map((event: any) => (
          <Event
            href={event.link}
            key={uniqid()}
            onClick={emitViewMeetupEvent}
            target="_blank"
          >
            <div>
              {nameEventComponent(event)}
              <ButtonLink href={event.link}>Get more info</ButtonLink>
            </div>
            {dateEventComponent(event, month)}
          </Event>
        ))}
      </EventOfTheMonth>
    ));

  return (
    <Layout ref={ref}>
      {!_.isEmpty(campusEvents) && <Title1 as="h2">{title}</Title1>}
      <>{eventsComponent()}</>
    </Layout>
  );
};

export default forwardRef<HTMLDivElement, Props>(UpcomingEvents);
