import { updateDataLayer } from '../../lib/datalayer';

function emitMoreInfoEvent(): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click link',
    eventLabel: 'banner::more information'
  });
}

export { emitMoreInfoEvent };
