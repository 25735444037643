import styled, { css } from 'styled-components';
import config from '../config';
import { Link } from 'gatsby';
import { Component } from '../atoms/body/text3/Component';

const CampusInformation = styled.section`
  width: calc(50% - 3.2rem);
  display: flex;
  flex-direction: column;

  & > b {
    margin-top: 3.2rem;
    margin-bottom: 0.8rem;
  }

  a {
    color: ${config.palette.lightBlue};
    text-decoration: none;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

const FindCampus = styled.article`
  margin-bottom: 3.2rem;

  & > b {
    margin-bottom: 0.8rem;
  }

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 1.8rem;
  }
`;

const ContactIronhack = styled.section`
  & > article {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 3.2rem;

    & > svg {
      width: 3.2rem;
      height: 3.2rem;
    }

    & > div {
      margin-left: 1.6rem;
    }
  }

  & > a {
    display: inline-block;
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    line-height: 130%;
    border: 2px solid #2d354c;
    border-radius: 0.6rem;
    padding: 2rem 6.4rem;
    text-decoration: none;
    color: #2d354c;

    @media (max-width: ${config.resolutions.mobileL}) {
      font-size: 1.4rem;
      padding: 1.6rem 3.6rem;
      margin-bottom: 1.2rem;
    }
  }
`;

const LinkTel = styled(Component)`
  color: ${config.palette.lightBlue};
`;

const LayoutCampusContactInformation = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;

  iframe {
    width: calc(50% - 3.2rem);
    margin: 2.4rem 0;
    border: none;
    border-radius: 0.6rem;

    @media (max-width: ${config.resolutions.mobileL}) {
      width: 100%;
      height: 32rem;
    }
  }
`;

export {
  FindCampus,
  CampusInformation,
  ContactIronhack,
  LinkTel,
  LayoutCampusContactInformation
};
