import { updateDataLayer } from '../../lib/datalayer';

function emitContactUsEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click cta::contact',
    eventLabel: 'contact form::contact us'
  });
}

export { emitContactUsEvent };
