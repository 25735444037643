import styled from 'styled-components';
import config from '../config';

const EventOfTheMonth = styled.section`
  margin-top: 3.2rem;

  & > b {
    color: ${config.palette.darkBlueTransparent('0.4')};
    margin-bottom: 0.8rem;
  }

  & > hr {
    margin: 0;
    width: 50%;
    height: 0.1rem;
    background-color: ${config.palette.darkBlueTransparent('0.1')};
    border-style: none;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    & > b,
    & > hr {
      display: none;
    }
  }
`;

const Event = styled.a`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.4rem;
  padding: 2.4rem 3.2rem 3.2rem;
  align-items: baseline;
  cursor: pointer;
  text-decoration: none;

  a {
    display: none;
  }

  & > div {
    width: 70%;
  }

  &:hover {
    background-color: ${config.palette.white};
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
    border-radius: 0.4rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;
    padding-bottom: 0.8rem;
    padding-top: 1.6rem;
    padding-left: 0;

    a {
      display: block;
    }

    & > div {
      width: 100%;
    }

    & > article {
      position: relative;
      margin-bottom: 1.6rem;

      &::after {
        content: ' ';
        width: 100%;
        background-color: ${config.palette.darkBlueTransparent('0.1')};
        height: 0.1rem;
        position: absolute;
        bottom: 0;
      }
    }
  }
`;

const NameEvent = styled.article`
  & > div {
    margin-top: 0.8rem;
    display: flex;
  }
  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
  }
`;

const AdressEvent = styled.div`
  p {
    display: inline-block;
    margin-left: 0.8rem;
    color: ${config.palette.darkBlueTransparent('0.6')};

    @media (max-width: ${config.resolutions.mobileL}) {
      width: 100%;
    }
`;

const DateEvent = styled.article`
  text-align: end;

  & > p {
    text-transform: uppercase;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > p {
      text-transform: capitalize;
      color: ${config.palette.darkBlueTransparent('0.4')};
      margin-bottom: 0.4rem;
      font-size: 1.4rem;
    }
  }
`;

const ButtonLink = styled.a`
  padding: 1rem 0;
  font-size: 1.5rem;
  line-height: 130%;
  border: 0.2rem solid #2dc5fa;
  color: #2dc5fa;
  border-radius: 0.6rem;
  text-decoration: none;
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
  text-align: center;
`;

export {
  EventOfTheMonth,
  Event,
  NameEvent,
  DateEvent,
  AdressEvent,
  ButtonLink
};
