import React from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import * as gaEvents from './ga_events';

interface Props {
  campusName?: string;
}

const linkMap = {
  en: '/en/news/transition-to-remote-education',
  es: '/es/noticias/transicion-aprendizaje-en-remoto',
  fr: '/fr/news/transition-to-remote-education',
  de: '/de/neuigkeiten/ubergang_zur_remote_lernerfahrung',
  nl: '/nl/nieuws/overstap-naar-remote-educatie',
  pt: '/pt/novidades/transitar-para-educacao-remota',
  br: '/br/novidades/transicao-para-educacao-remota',
};

const CovidContextualBanner: React.SFC<any> = (props: Props) => {
  const { campusName = '' } = props;
  const { t, i18n } = useTranslation();
  const key = campusName.length > 0 ? 'contextualCampus' : 'contextual';

  return (
    <Box
      as="section"
      bgColor="yellow.100"
      borderRadius={[null, null, 'card']}
      maxW={[null, null, null, '1296px']}
      mb={[6, null, 8]}
      mt={[-3, null, 6]}
      mx="auto"
      p={4}
    >
      <Text textStyle="body1">{t('covid:covid.contextual.title')}</Text>
      <Text mt={1.5} textStyle="body3">
        {t(`covid:covid.${key}.description`, {
          campus: campusName,
        })}{' '}
        <Link
          href={`${linkMap[i18n.language]}`}
          onClick={gaEvents.emitMoreInfoEvent}
          variant="darkBlue"
        >
          {t(`covid:covid.${key}.linkText`)}
        </Link>
      </Text>
    </Box>
  );
};

export default CovidContextualBanner;
