import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import ListComponent from '../ListComponent';
import TeamMember from '../TeamMember';
import { getCampusName, isVirtualCampus } from '../../lib/campus';
import { LayoutListTeam, ListMembers } from './styles';
import { staffByCampus, useStaff } from '../../services/datoStaff';

interface Props {
  campusCode: string;
  locale: string;
}

const TeamCampus: React.SFC<any> = (props, ref) => {
  const { campusCode, locale } = props;
  const { t } = useTranslation();
  const staff = useStaff(locale);

  const campusStaff = staffByCampus(
    staff,
    isVirtualCampus(campusCode) ? 'rmt' : campusCode
  );

  return (
    <Layout extend={LayoutListTeam} ref={ref}>
      <Title1 as="h2">
        {t('staff:staff.campusPageTitle', {
          campus: getCampusName(campusCode),
        })}
      </Title1>
      <ListMembers>
        <ListComponent ChildrenComponent={TeamMember} listItems={campusStaff} />
      </ListMembers>
    </Layout>
  );
};

export default forwardRef<HTMLDivElement, Props>(TeamCampus);
