import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import Layout from '../atoms/layout';
import { Text1, Text3 } from '../atoms/body';
import {
  CampusInformation,
  ContactIronhack,
  FindCampus,
  LayoutCampusContactInformation,
} from './styles';
import { emitContactUsEvent } from './ga_events';
import Props from '../interfaces/CampusContactInformation';
import { showPhoneNumberByCampus } from '../../lib/campus';
import { getLinkByPageName } from '../../lib/links';
import WhatsappLogo from '../../../static/assets/icons/campus/whatsapp-logo.svg';

const CampusContactInformation: React.SFC<any> = (props, ref) => {
  const { campusContactInfo, campusCode, title, hideLocation } = props;
  const { t } = useTranslation();
  const { street, zipCode, country, phoneNumber, email, googleMapsUrl } =
    props.campusContactInfo[0];
  const campusName = campusContactInfo[0].campus.description;

  if (campusCode === 'rmt') {
    return (
      <Layout extend={LayoutCampusContactInformation} ref={ref}>
        <CampusInformation>
          <ContactIronhack>
            <Text1>
              {t('campus-contact-info:campusContactInfo.contact.title', {
                campus: campusName,
              })}
            </Text1>
            <div>
              <Text3 as="p">
                {t('campus-contact-info:campusContactInfo.contact.email')}
              </Text3>
              <Text3 as="a" href={`mailto:${email}`}>
                {email}
              </Text3>
            </div>
          </ContactIronhack>
        </CampusInformation>
      </Layout>
    );
  }

  return (
    <Layout extend={LayoutCampusContactInformation} ref={ref}>
      {title && (
        <Text mb={3} textStyle="title2" w="100%">
          {title}
        </Text>
      )}
      <CampusInformation
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
      >
        {!hideLocation && (
          <FindCampus>
            <Text1>
              {t('campus-contact-info:campusContactInfo.title', {
                campus: campusName,
              })}
            </Text1>
            <Text3 as="p">
              {t('campus-contact-info:campusContactInfo.subtitle', {
                campus: campusName,
              })}
            </Text3>
            <Text3 as="p">
              <span itemProp="streetAddress">{street}</span> , {''}
              <span itemProp="postalCode">{zipCode}</span> - {''}
              <span itemProp="addressLocality">{campusName}</span> {''}
              <span itemProp="addressCountry">({country})</span>
            </Text3>
            {campusCode === 'par' && (
              <Text color="darkBlue.64" fontWeight="bold" textStyle="body3">
                {t('campus-contact-info:campusContactInfo.parisLegalNotice')}
              </Text>
            )}
          </FindCampus>
        )}
        <ContactIronhack>
          {showPhoneNumberByCampus(campusCode) && (
            <>
              <Text1>
                {t('campus-contact-info:campusContactInfo.contact.title', {
                  campus: campusName,
                })}
              </Text1>
              <article>
                <WhatsappLogo />
                <div>
                  <Text3 as="p">
                    {t('campus-contact-info:campusContactInfo.contact.message')}
                  </Text3>
                  <Text3
                    as="a"
                    href={`tel:${phoneNumber}`}
                    itemProp="telephone"
                  >
                    {phoneNumber}
                  </Text3>
                </div>
              </article>
            </>
          )}
          <a href={getLinkByPageName('contact')} onClick={emitContactUsEvent}>
            {t('campus-contact-info:campusContactInfo.cta')}
          </a>
        </ContactIronhack>
      </CampusInformation>
      {!hideLocation && <iframe src={googleMapsUrl} />}
    </Layout>
  );
};

export default forwardRef<HTMLDivElement, Props>(CampusContactInformation);
