import styled, { css } from 'styled-components';
import config from '../config';

const ListMembers = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.2rem;

  & > article {
    margin-bottom: 1.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;

const LayoutListTeam = css`
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-top: 5.1rem;
  }
`;

export { ListMembers, LayoutListTeam };
