import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { Box } from '@chakra-ui/react';

import { CampusPage } from '../../../interface/template';
import { SaveCampusVisited } from '../../../storage/provider';
import MeetupsByCampus from '../../../services/meetupsByCampus';
import DatoContactInfo from '../../../services/datoCampusContactInformation';
import { getLinkByPageName } from '../../../lib/links';
import {
  getCampusFacebook,
  getCampusInstagram,
  getCampusLinkedIn,
  getCampusMeetup,
  getCampusName,
  getCampusTwitter,
} from '../../../lib/campus';
import CampusValueProposition from '../../../components/CampusValueProposition';
import TeamCampus from '../../../components/TeamCampus';
import UpcomingEvents from '../../../components/UpcomingEvents';
import CampusContactInformation from '../../../components/CampusContactInformation';
import CovidContextualBanner from '../../../components/CovidContextualBanner';
import BlogArticlesSample from '../../../components/BlogArticlesSample';
import Footer from '../../../components/Footer';
import HeaderBgImage from '../../../containers/HeaderBgImage';
import HomeCourses from '../../../containers/HomeCourses';
import useScroll from '../../../hooks/useScroll';
import NewNavbar, {
  MenuProps,
} from '../../../components/CampusCourseNavbar/experiments';
import useBlogArticles from '../../../hooks/useBlogArticles';
import NewCampusValueProposition from '../../../containers/NewCampusValueProposition';
import Banner from '../../../components/Banner';
import useUserLocation from '../../../hooks/useUserLocation';

const Campus = (props: CampusPage): React.ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { locale, campusCode, campusCourses, pageName } = props.pageContext;

  const navbarRef = useRef<HTMLDivElement>(null);
  const scrollConfig = { offset: navbarRef?.current?.offsetHeight };
  const { userLocation } = useUserLocation();

  const [, headerRef, headerIsVisible] = useScroll(scrollConfig, '0%');
  const [scrollToCourses, coursesRef, coursesIsVisible] =
    useScroll(scrollConfig);
  const [scrollToWhy, whyRef, whyIsVisible] = useScroll(scrollConfig);
  const [scrollToTeam, teamRef, teamIsVisible] = useScroll(scrollConfig);
  const [scrollToEvents, eventsRef, eventsIsVisible] = useScroll(scrollConfig);
  const [scrollToContact, contactRef, contactIsVisible] =
    useScroll(scrollConfig);
  const [scrollToBlog, blogRef, blogIsVisible] = useScroll(scrollConfig);

  const campusName = getCampusName(campusCode);
  useEffect(() => {
    SaveCampusVisited(campusCode);
  }, [campusCode]);

  const meetupsByCampus =
    campusCode !== 'rmt'
      ? new MeetupsByCampus(campusCode).meetupsByCampusName()
      : null;

  const campusContactInfo = new DatoContactInfo(
    locale
  ).contactInformationByCampus(campusCode);

  const url = getLinkByPageName(pageName);

  const campusesWithCovidBanner = ['mex', 'sao'];

  const datoCampusInfo = props.data.allDatoCmsCampusinformation.nodes[0];

  const articles = useBlogArticles(campusCode.toUpperCase());

  const hideMenuItemIfNoContent = (menu: MenuProps) =>
    (!_.isEmpty(articles) || menu.key !== t('campus:campus.navbar.blog')) &&
    (!_.isEmpty(meetupsByCampus) ||
      menu.key !== t('campus:campus.navbar.events'));

  const navbarMenu = _.filter(hideMenuItemIfNoContent)([
    {
      isVisible: whyIsVisible || headerIsVisible,
      key: t('campus:campus.navbar.why', {
        campus: campusName,
      }),
      ref: whyRef,
      scroll: scrollToWhy,
    },
    {
      isVisible: coursesIsVisible,
      key: t('campus:campus.navbar.courses'),
      ref: coursesRef,
      scroll: scrollToCourses,
    },
    {
      isVisible: teamIsVisible,
      key: t('campus:campus.navbar.team'),
      ref: teamRef,
      scroll: scrollToTeam,
    },
    {
      isVisible: eventsIsVisible,
      key: t('campus:campus.navbar.events'),
      ref: eventsRef,
      scroll: scrollToEvents,
    },
    {
      isVisible: contactIsVisible,
      key: t('campus:campus.navbar.contact', {
        campus: campusName,
      }),
      ref: contactRef,
      scroll: scrollToContact,
    },
    {
      isVisible: blogIsVisible,
      key: t('campus:campus.navbar.blog'),
      ref: blogRef,
      scroll: scrollToBlog,
    },
  ]);

  const isLondonInEnglish = language === 'en' && campusCode === 'lon';

  return (
    <Box
      itemScope
      itemType="http://schema.org/EducationalOrganization"
      maxW="144rem"
      mx="auto"
    >
      <meta
        content="https://www.ironhack.com/assets/icons/ironhack_logos/logo.svg"
        itemProp="logo"
      />
      <meta content={url} itemProp="url" />
      <meta content={`Ironhack ${getCampusName(campusCode)}`} itemProp="name" />
      <meta content={getCampusFacebook(campusCode)} itemProp="sameAs" />
      <meta content={getCampusTwitter(campusCode)} itemProp="sameAs" />
      <meta content={getCampusInstagram(campusCode)} itemProp="sameAs" />
      <meta content={getCampusLinkedIn(campusCode)} itemProp="sameAs" />
      <meta content={getCampusMeetup(campusCode)} itemProp="sameAs" />
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      {isLondonInEnglish && <Banner type="londonWhitepaper" />}
      <HeaderBgImage
        cta={datoCampusInfo?.header?.cta}
        description={datoCampusInfo?.header?.description}
        imageDesktop={datoCampusInfo?.header?.imageDesktop}
        imageMobile={datoCampusInfo?.header?.imageMobile}
        onCtaClick={scrollToCourses}
        pageName={pageName}
        ref={headerRef}
        subtitle={datoCampusInfo?.header?.subtitle}
        title={datoCampusInfo?.header?.title}
      />
      <NewNavbar
        ctaProps={{
          onClick: scrollToCourses,
        }}
        ctaText={t(`campus:campus.common.chooseCourseCTA`)}
        headerIsVisible={headerIsVisible}
        menu={navbarMenu}
        ref={navbarRef}
        titleForMobile={t('campus:campus.navbar.header', {
          campus: campusName,
        })}
      />
      {!_.isEmpty(datoCampusInfo?.campusvalueprops) ? (
        <NewCampusValueProposition campusInfo={datoCampusInfo} ref={whyRef} />
      ) : (
        <CampusValueProposition
          campusCode={campusCode}
          locale={locale}
          ref={whyRef}
        />
      )}
      <HomeCourses
        campusCode={campusCode}
        campusCourses={campusCourses}
        downloadSyllabus
        ref={coursesRef}
      />
      <TeamCampus campusCode={campusCode} locale={locale} ref={teamRef} />
      {campusCode !== 'rmt' && (
        <UpcomingEvents
          campusCode={campusCode}
          locale={locale}
          meetups={meetupsByCampus}
          ref={eventsRef}
          title={t('upcoming-events:upcomingEvents.title', {
            campus: campusName,
          })}
        />
      )}
      <CampusContactInformation
        campusCode={campusCode}
        campusContactInfo={campusContactInfo}
        hideLocation={['mex'].includes(campusCode)}
        ref={contactRef}
        title={t('campus-contact-info:campusContactInfo.mainTitle')}
      />
      {campusesWithCovidBanner.includes(campusCode) && (
        <CovidContextualBanner campusName={campusName} />
      )}
      <BlogArticlesSample category={campusCode.toUpperCase()} ref={blogRef} />
      <Footer page={pageName} />
    </Box>
  );
};

export default Campus;
